import React, { useState } from 'react';
import './FloatingPhoto.css';
import profile_picture from './Images/profile_picture.jpeg';
import githubImage from './Images/github.png';
import linkedinImage from './Images/linkedin.png';
import youtubeImage from './Images/youtube.png';
import aboutImage from './Images/about.png';

const FloatingPhoto = () => {
   const [isClicked, setIsClicked] = useState(false);
   const [isMoved, setIsMoved] = useState(false);
   const [clickedLink, setClickedLink] = useState(null);
   const [youtubeLink, setYoutubeLink] = useState(null);

   const handlePhotoClick = () => {
      setIsClicked(!isClicked);
      setTimeout(() => {
         setIsMoved(!isMoved);
      }, 500);
   };

   const handleClick = (link) => {
      if (link === 'YouTube') {
         setYoutubeLink(true);
      }
      setClickedLink(link);
   };

   const handleBack = () => {
      setYoutubeLink(false);
      setClickedLink(null);
   };

   return (
      <div className="photo-container">
         <img
            src={profile_picture}
            alt="Flottante"
            className={`floating-photo${isClicked ? '-clicked' : ''}${isMoved ? '-moved' : ''}`}
            onClick={handlePhotoClick}
         />
         {isClicked && (
            !clickedLink ? (
               <div className="links">
                  <div key={1} className='link-item'>
                     <a
                        key={2}
                        href="https://github.com/nomduprofil"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                     >
                        <img src={githubImage} alt='GitHub' className="link" />
                     </a>
                  </div>
                  <div key={2} className='link-item'>
                     <a
                        key={2}
                        href="https://www.linkedin.com/in/valentin-chaigneau/"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                     >
                        <img src={linkedinImage} alt='LinkedIn' className="link" />
                     </a>
                  </div>
                  <div key={3} className='link-item'>
                     <a
                        key={3}
                        href="/#"
                        target="_self"
                        rel=""
                        className="link"
                        onClick={(e) => {
                           e.preventDefault();
                           handleClick('YouTube');
                        }}>
                        <img src={youtubeImage} alt='YouTube' className="link" />
                     </a>
                  </div>
                  <div key={4} className='link-item'>
                     <a
                        key={4}
                        href="/#"
                        target="_self"
                        rel=""
                        className="link"
                        onClick={(e) => {
                           e.preventDefault();
                           handleClick('About');
                        }}>
                        <img src={aboutImage} alt='About' className="link" />
                     </a>
                  </div>
               </div>
            ) : (
               <div className="container">
                  {youtubeLink ? (
                     <div className='video-list'>
                        <a
                           href="https://youtu.be/hYyzk8TTbjo"
                           target="_blank"
                           rel="noreferrer"
                           className="video-link"
                        >
                           0x501 - Teknik - L'importance des rapports en sécurité offensive
                        </a>
                        <a
                           href="https://youtu.be/PCc5vzEyuDY"
                           target="_blank"
                           rel="noreferrer"
                           className="video-link"
                        >
                           Artificial Intelligence and Neural Networks
                        </a>
                        <a
                           href="https://youtu.be/gdWsi_aSCqk"
                           target="_blank"
                           rel="noreferrer"
                           className="video-link"
                        >
                           Jajishing - A Serverless phishing solution
                        </a>
                     </div>
                  ) : (
                     <div className="about">
                        <p>
                           At the end of my engineering studies at INSA CVL, I had the opportunity to work for a small company named EGYDE (which later joined KPMG), composed of fewer than ten people. I didn't know it at the time, but this experience was going to allow me, and still does today, to explore many aspects of cybersecurity.<br /><br />
                           Penetration testing, incident response, phishing campaigns, automation, and even developing a "serverless" web portal: every day is different, which has allowed me to acquire a somewhat unique professional background.<br /><br />
                           During these years, I was fortunate to collaborate with many companies of varying sizes, from small to large enterprises, around the world, from Africa to the United States and, of course, Canada. This diversity has particularly allowed me to understand that issues depend not only on technical aspects but also on the business context.<br /><br />
                           And what better way to combine business and technical skills than developing a tool? It was in a café in Montreal (those who know, know) that I began developing the initial algorithms for a tool designed to optimize my workdays. This tool became an API and today, a serverless web portal on AWS (I would like to thank the person who made this progress possible), of which I am the "Product Owner," with a team of three people whom I also salute.<br /><br />
                           Although this project is close to my heart, it is not my main activity. Indeed, I am primarily responsible for the phishing practice at KPMG and also a pentester. I have had the opportunity to conduct external, internal, Wi-Fi, and application penetration tests.<br /><br />
                           But what better way to understand these attacks than to investigate one of them? Through incident response, I have had the chance to work on major cybersecurity incidents, which has allowed me to grasp the impacts of attacks both operationally and in terms of business.<br /><br />
                           With the word limitation, I will stop here. I would have liked to say more, but perhaps I will do so in a video (as you can see on my profile). If I had to define my career as I see it today, I would say it resembles that of a "Swiss Army knife" (those who know, know).<br /><br />
                           Random Hashtag: #Thanks #JAJN3 #RénoDépôt
                        </p>
                     </div>
                  )}
                  <button className="back-button" onClick={handleBack}>&#x2190;</button>
               </div>
            )
         )}
      </div>
   );
};

export default FloatingPhoto;
